* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@media only screen and (max-width: 767px) {
  .pc-only {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1100px) {
  .pc-only {
    display: none;
  }
}

@media only screen and (min-width: 1101px) {
  .pc-only {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .sp-only {
    display: block;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1100px) {
  .sp-only {
    display: block !important;
  }
}

@media only screen and (min-width: 1101px) {
  .sp-only {
    display: none !important;
  }
}

a:-webkit-any-link {
  color: #666;
  pointer-events: all;
  transition: color .4s ease-out;
}

a:-webkit-any-link:hover {
  color: #c4c4c4 !important;
}

@font-face {
  font-family: 'calaiso';
  src: url(/assets/fonts/Calaiso_Regular.ttf);
}

html,
body {
  background-color: #000000;
}

ul,
li {
  list-style: none;
}

.webgl {
  position: fixed;
  top: 0;
  right: 0;
  outline: none;
  width: 100%;
}

#logo {
  position: absolute;
  top: 40px;
  left: 80px;
  display: block;
  z-index: 3;
}

@media only screen and (max-width: 767px) {
  #logo {
    left: 24px;
    top: 24px;
  }
}

#logo img {
  width: 60px;
}

@media only screen and (max-width: 767px) {
  #logo img {
    width: 40px;
  }
}

#intro {
  position: absolute;
  bottom: 40px;
  right: 40px;
  display: block;
  z-index: 3;
  color: white;
  font-size: 14px;
  font-family: 'calaiso', sans-serif;
  color: #666666;
  line-height: 1.6;
}

@media only screen and (max-width: 767px) {
  #intro {
    bottom: 24px;
  }
}

#menu {
  position: absolute;
  width: 10%;
  left: 0;
  top: 0;
  height: 100%;
  display: block;
  z-index: 10;
  text-align: center;
  font-family: 'calaiso', sans-serif;
  padding-top: 260px;
}

#menu ul {
  margin: 40px auto;
  text-align: center;
  color: white;
  padding: 8px;
}

#menu ul li {
  margin: 40px auto;
}

#menu p {
  margin-top: 40%;
  font-size: 12px;
}

#enter {
  pointer-events: all !important;
  position: absolute;
  width: 200px;
  padding: 8px 0;
  left: 0;
  right: 0;
  margin: auto;
  margin-top: 48%;
}

@media only screen and (max-width: 767px) {
  #enter {
    margin-top: 480px;
  }
}

#enter h3 {
  border-left: 0.2px solid #666;
  border-bottom: 0.2px solid #666;
  display: block;
  text-align: center;
  color: #666;
  font-family: 'calaiso', sans-serif;
  font-size: 24px;
  z-index: 10;
  padding: 8px 0;
  cursor: pointer;
}

#enter h3:hover {
  color: #c4c4c4;
}

#portfolio {
  background-color: black;
}

@media only screen and (max-width: 767px) {
  #portfolio {
    height: 100vh !important;
    overflow: hidden;
  }
}

#p-menu {
  position: absolute;
  right: 0;
  top: 120px;
}

@media only screen and (max-width: 767px) {
  #p-menu {
    top: 80px;
    height: 400px;
  }
}

#p-menu .p-menu_contents_li {
  overflow: hidden;
  display: flex;
  margin-top: 3.8rem;
  line-height: 1;
  cursor: pointer;
  list-style-position: outside;
  list-style-type: none;
  text-align: -webkit-match-parent;
}

#p-menu .p-menu_contents_li a {
  text-decoration: none;
}

#p-menu .link {
  position: relative;
  display: block;
  overflow: hidden;
  line-height: 1;
}

#p-menu .link:hover > .link-text {
  transform: skewY(12deg) translateY(-150%);
}

#p-menu .link:hover > .link-text_follow {
  transform: none;
  color: #c4c4c4;
}

#p-menu .link-text {
  font-size: 120px;
  display: block;
  color: #666666;
  will-change: transform;
  font-family: 'calaiso', sans-serif;
  transition: transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translateZ(0);
  position: relative;
  transform-origin: right bottom;
  padding: 0.4rem 0.2rem;
}

@media only screen and (max-width: 767px) {
  #p-menu .link-text {
    font-size: 48px;
  }
}

#p-menu .link-text_follow {
  position: absolute;
  transform-origin: 0% 100%;
  font-size: 120px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0.4rem 0.2rem;
  color: #666666;
  will-change: transform;
  font-family: 'calaiso', sans-serif;
  transition: transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translateY(125%) skewY(24deg);
}

@media only screen and (max-width: 767px) {
  #p-menu .link-text_follow {
    font-size: 48px;
  }
}

#copy {
  position: absolute;
  bottom: 40px;
  right: 80px;
  font-family: 'calaiso', sans-serif;
  color: #666666;
  font-size: 14px;
}

@media only screen and (max-width: 767px) {
  #copy {
    bottom: 24px;
    right: 0;
    left: 0;
    margin: auto;
    text-align: center;
  }
}

#sns {
  position: absolute;
  bottom: 40px;
  left: 80px;
  font-size: 24px;
  color: #666666;
}

@media only screen and (max-width: 767px) {
  #sns {
    left: 0;
    right: 0;
    margin: auto;
    bottom: 80px;
  }
}

#sns ul {
  display: flex;
  align-content: center;
  gap: 56px;
}

@media only screen and (max-width: 767px) {
  #sns ul {
    justify-content: center;
  }
}

#sns ul .sns {
  padding: .8rem;
  margin: -.625rem;
  line-height: 1.72727;
  color: #666;
}

.portfolio-pdf {
  width: 100%;
  height: 100vh;
}

@media only screen and (max-width: 767px) {
  .portfolio-pdf {
    width: 100%;
  }
}

#conceptArt {
  color: #c4c4c4;
  font-family: 'calaiso',sans-serif;
  overflow: scroll;
}

.wrapper {
  width: 100%;
  margin: 200px auto 160px auto;
}

@media only screen and (max-width: 767px) {
  .wrapper {
    margin: 120px auto 80px auto;
  }
}

.catWide {
  width: 80%;
  margin: 80px auto;
}

@media only screen and (max-width: 767px) {
  .catWide {
    width: 92%;
    margin: 64px auto;
  }
}

.catWide_title h3 {
  font-size: 56px;
}

@media only screen and (max-width: 767px) {
  .catWide_title h3 {
    font-size: 36px;
  }
}

.catWide_img {
  padding: 40px 0;
  text-align: center;
}

.catWide_img img {
  width: 100%;
}

.catWide_description {
  margin: 24px 0;
}

@media only screen and (max-width: 767px) {
  .catWide_description {
    margin: 12px 0;
  }
}

.catWide_description_title {
  margin: 16px 0;
  font-size: 24px;
}

.catWide_description_title .small {
  padding-left: 24px;
  font-size: 14px;
  color: #666666;
}

.catWide_description_para {
  color: #666666;
  padding: 12px 0;
}
